import dayjs from 'dayjs'

export default {
    title: 'Roof Replacement Estimate',
    opportunity: '66ccdea4c1a418ffc49d3a86',
    branch: '65430a0898313861305523dd',
    jobDetails: {
        customerName: 'dustin henager',
        email: 'dustin.henager@gmail.com',
        phone: '7578410610',
        street: '1856 grandin dr',
        city: 'Centennial',
        state: 'CO',
        zip: '80112',
        existingRoofMaterial: 'Laminate',
        supplier: '66ce1d47988031d25931236c',
        shingleChoice: 'oc-duration',
        shingleColor: 'Estate Gray',
        sub: '66ce1d47988031d25931236b',
        replacementType: 'Full Replacement',
        leadType: 'Self Originated',
        materialDeliveryDate: dayjs().format('YYYY-MM-DD'),
        tentativeInstallDate: dayjs().format('YYYY-MM-DD'),
    },
    dimensions: {
        totalRoofArea: 4975,
        facets7_12: 3210,
        facets8_12: 0,
        facets9_12: 0,
        facets10_12: 750,
        facets11_12: 0,
        facets12_12: 0,
        noAccessSQ: 0,
        ridgesLF: 246.1,
        hipsLF: 54,
        valleysLF: 163,
        eavesLF: 249,
        rakesLF: 247.1,
        ridgeVentLF: 246.1,
        eaveExp: 0,
        perimeterLF: 496.1,
        squares: 50,
        stepFlash: 92,
        wallFlash: 0,
        xlDripEdgeArea: 0,
        baseCapSheet: 0,
        roofPitch: "4/12",
        secondStorySF: 3,
    },
    structures: {
        gutters: false,
        existingDripEdge: true,
        numChimneys: 2,
        numSkyLights: 0,
        newVent: true,
        gutterReset: false,
        antennas: true,
        addStructures: true,
        powerVentCover: 0,
        crickets: 0,
        mansard: 0,
        existingVent: "Box Vent",
        broanVents: 0,
        atticAC: false,
        replaceSkylight: 0,
        flashSkylight: 0,
    },
    materials: {
        boxVent750: 0,
        pipeBoots: 0,
        leadPipeBoot: 0,
        electricSplitBoot: 5,
        ccBV: 0,
        supplyCaulk: false,
        sprayPaint: false,
        decking: 0,
    },
    details: {
        extraLayers: 0,
        chargedTrips: 0,
        extFeltLayers: 0,
        waste: 15,
        replaceDecking: 0,
        orderNewDripEdge: false,
        includeIceAndWaterOnEaves: false,
    },
    materialItems: {
        hipAndRidgeCap: "oc-proedge-33lf",
        starter: "oc-starter-strip-100-lf",
        iceAndWater: "oc-weatherlock",
        underlayment: "oc-rhino",
        dripEdge: "2x4-black",
        ridgeVent: "oc-ventsure-4ft-strip",
        boxVent: "0",
        powerAssistedVent: "replace-cover-only",
        pipeBoots: "125--3-neoprene",
        leadPipeBoot: "lead-pipe-boot",
        electricSplitBoot: "electric-split-boot",
        wallFlashing: "wall-flashing-4x4x10",
        stepFlashing: "step-flashing-4x4x8",
        counterFlashing: "counter-flashing",
        coilNails: "coil-nails-1-12",
        buttonCapNails: "button-cap-nails-1",
        decking: "osb",
        caulking: "geo-cel-sealant",
        capSheet: "mule-hide-top-shield-pro",
        baseSheet: "mule-hide-top-shield-pro-base",
        xlDripEdge: "aluminum-2x4-painted",
        broanVent: "broan-636",
    },
    laborItems: {
        sqToBeInstalled: 25,
        sqToBeRemoved: 25,
        steepFacetsSq712: 5,
        steepFacetsSq812: 3,
        steepFacetsSq912: 2,
        steepFacetsSq1012: 1,
        steepFacetsSq1112: 0,
        steepFacetsSq1212: 0,
        extraLayers: 1,
        decking: 20,
        dumpFeesHaulOff: 500,
        tripCharge: 50,
        guttersRemoved: 0,
        noAccessPerSq: 0,
        installCricketsEach: 1,
        cutInCoverUpBoxVentsEach: 4,
        replaceSkylightFlashingInc: 0,
        skylightFlashingReFlash: 2,
        counterFlashing: 0,
        decking1xBoardsLft: 100,
        extraLayerFeltTearOff: 1,
        mansard: 0,
        stepFlashing: 15,
        secondStoryPerSq: 10,
        cutInRidgeVent: 1,
        chimneyFlashing: 1,
    },
    cashEstimate: {
        cashEstimate: false,
        subTotal: 10000,
        costPerSq: 400,
        warrantySold: 1000,
        desiredProfitMargin: 0.42,
        pricePerSq: 0,
        totalPrice: 0,
        profit: 0,
        marginBeforeCommission: 0,
        commission: 0,
        marginAfterCommission: 0,
        totalProjectCost: 0,
    },
    insuranceWork: {
        acvCheck: 0,
        recoverableDepreciationAndTaxes: 0,
        deductible: 0,
        totalInsuranceRCV: 0,
        roofRCV: 0,
        workNotDoing: 0,
        supplements: 0,
        oAndP: 0,
        warrantySold: 0,
        addlOutOfPocketExpenses: 0,
        profit: 0,
        marginBeforeCommission: 0,
        commission: 0,
        marginAfterCommission: 0,
        totalProjectCost: 0,
    },
    outOfScope: {
        line1: 0,
        line2: 0,
        line3: 0,
        line4: 0,
        line5: 0,
        line6: 0,
        line7: 0,
        line8: 0,
        line9: 0,
        line10: 0,
        oosTotal: 0,
    },
    overhead: {
        line1: 0,
        line2: 0,
        line3: 0,
        line4: 0,
        line5: 0,
        line6: 0,
        line7: 0,
        line8: 0,
        line9: 0,
        line10: 0,
        oAndPTotal: 0,
        oosOAndPTotal: 0,
    },
    commissionTriggers: {
        freeUpgradeDrop: true,
        drpProgram: true,
        drpAmount: 500,
        secondEmployeeType: "Sales Rep",
    },
    commissionItems: {
        deductible: 0,
        totalProjectCost: 0,
        commissionPercentage: 0,
        B19: true,
        B20: false,
        B21: 0,
        C19: 0,
        C20: 0,
        C21: 0,
        commissionNet: 0,
        C23: 0,
        C24: 0,
        deductibleSplit: 0,
        oAndPSplit: 0,
        warrantySold: 0,
        warrantyCommission: 0,
        drpCreditAmount: 0,
        repTotalPay: 0,
    },
    customerPaymentSheet: null,
    customerQuote: null,
    materialOrder: null,
    workOrder: null,
    commissionSheet: null,
    propertyDetails: null,
};
